<template>
    <div>
        <div class="page-title">纸质报告兑换码</div>

        <div class="mb-4">
            <button class="btn" @click="activeCodeModal()">生成兑换码</button>
        </div>

        <div>
            <a-table :columns="table.columns"
                     :rowKey="record => record.id || record.key"
                     :data-source="table.data"
                     :pagination="table.pagination"
                     @change="tableChanged"
                     :locale="{emptyText:'暂无兑换码'}"
                     class="members-table"
                     :loading="table.loading"
            >
                <div slot="time" slot-scope="time">
                    {{ formatTime(time) }}
                </div>

                <div slot="code" slot-scope="code">
                    <span @click="copyCode(code)" class="code-info">{{ code }}</span>
                </div>

                <div slot="op" slot-scope="val,item">
                    <div v-if="item.invalid">
                        <span class="text-minor">已作废</span>
                    </div>
                    <div v-else-if="!item.used_at">
                        <template v-if="item.send">
                            <span class="text-warning">已发送</span>
                            <a class="ml-2" @click="invalid(item.id)"><small>作废</small></a>
                            <a class="ml-2" @click="send(item.id,0)"><small>标记为未发送</small></a>
                        </template>
                        <template v-else>
                            <span class="text-success">正常</span>
                            <a class="ml-2" @click="send(item.id,1)"><small>标记为发送</small></a>
                        </template>

                    </div>
                    <div v-else>
                        <a :href="`ast/members/${ item.used_ast_member.id}`">{{
                            item.used_ast_member.mark_name || item.used_ast_member.name
                            }}</a>
                        <br>
                        <span class="text-success">使用于 {{ formatTime(item.used_at) }}</span>
                    </div>
                </div>

            </a-table>
        </div>

        <a-modal v-model="showCodeModal" title="生成兑换码" okText="生成" @ok="generateCode">
            <at-input v-model="codeNum" highlight class="full-with" unit="生成数量"></at-input>
        </a-modal>
    </div>
</template>

<script>
import moment from "moment";
import api from "@/repo/api";
import copy from "copy-to-clipboard";
import AtInput from "@/components/AtInput.vue";

export default {
    name: "Code",
    components: {AtInput},
    data() {
        return {
            table: {
                columns: [
                    {
                        title: 'ID',
                        dataIndex: 'id',
                        width: '110px',
                    },
                    {
                        title: '兑换码',
                        dataIndex: 'code',
                        scopedSlots: {customRender: 'code'},
                    },
                    {
                        title: '生成时间',
                        dataIndex: 'created_at',
                        scopedSlots: {customRender: 'time'},
                    },
                    {
                        title: '状态',
                        dataIndex: 'op',
                        scopedSlots: {customRender: 'op'},
                    }
                ],
                loading: true,
                pagination: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 15,
                    showTotal: (total, range) => {
                        return `当前${range[0]}-${range[1]}，共 ${total} 个会员`;
                    },
                },
                data: []
            },
            query: {},
            showCodeModal: false,
            codeNum: "1",
        }
    },
    mounted() {
        this.getFirstList()
    },
    methods: {
        activeCodeModal() {
            this.codeNum = 1
            this.showCodeModal = true
        },
        copyCode(code) {
            copy(code)
            this.$message.success('复制成功')
        },
        formatTime(time) {
            return moment(time).format('yyyy-MM-DD HH:mm')
        },
        getFirstList() {
            this.query.page = 1
            this.getList()
        },
        generateCode() {
            this.$loading.show()

            api.post('/ast-detect/generate-code', {num: this.codeNum}, (res) => {

                this.$loading.hide()

                this.$message.data(res)

                if (res.code === 0) {
                    this.showCodeModal = false
                    this.getList()
                }

            })
        },
        getList() {

            this.table.loading = true

            api.get('/ast-detect/codes', this.query, (data) => {

                this.table.data = data.data.data
                this.table.pagination.current = data.data.current_page
                this.table.pagination.total = data.data.total
                this.table.pagination.pageSize = data.data.per_page

                this.table.loading = false

            })
        },
        send(id, status) {

            this.$loading.show()

            api.post('/ast-detect/send-code', {id, status}, (res) => {

                this.$loading.hide()

                this.$message.data(res)

                if (res.code === 0) this.getList()

            })
        },
        invalid(id) {
            this.$confirm({
                title: '确定要作废这个验证码吗？',
                onOk: () => {

                    this.$loading.show()

                    api.post('/ast-detect/invalid-code', {id}, (res) => {

                        this.$loading.hide()

                        this.$message.data(res)

                        if (res.code === 0) this.getList()

                    })

                }
            })
        },
        tableChanged(pagination) {
            this.query.page = pagination.current
            this.getList()
        }
    }
}
</script>

<style scoped lang="less">
.code-info {
    border: 1px solid #dddddd;
    padding: 5px 10px;
    border-radius: 6px;
    cursor: pointer;
    user-select: none;
}
</style>